

import * as React from "react";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";

function withTestID(testID, element) {
  if (testID !== undefined) {
    return React.cloneElement(element, {
                "data-testid": Caml_option.valFromOption(testID)
              });
  } else {
    return element;
  }
}

export {
  withTestID ,
  
}
/* react Not a pure module */
